import React from "react";

const About = () => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <h1>
                Elias is sus.
            </h1>
        </div>
    )
}

export default About;