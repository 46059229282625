import React from 'react';
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    NavBtn,
    NavBtnLink,
} from "./navbarElements";
import './navbar.css';

function Navbar() {
    return(
        <div className='Navbar'>
            <div className='left-btns'>
                <Nav>
                    <Bars />

                    <NavMenu>
                        <NavLink to='/'>
                            Home
                        </NavLink>
                        <NavLink to="/about">
                            About
                        </NavLink>
                        <NavLink to="/Elias">
                            Elias
                        </NavLink>
                        <NavLink to="/Is">
                            Is
                        </NavLink>
                    </NavMenu>
                </Nav>
                </div>
                <div className='right-btns' id="highlight">
                <Nav>
                    <Bars />

                    <NavMenu>
                        <NavBtn>
                            <NavBtnLink class="highlight" to="/login">
                                Log In
                            </NavBtnLink>
                        </NavBtn>
                    </NavMenu>
                </Nav>
            </div>
        </div>
    );
}

export default Navbar