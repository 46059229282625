import React, { useState } from "react";
import PropTypes from 'prop-types';

async function loginUser(credentials) {
    return fetch('http://localhost:8080/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
    .then(data => data.json())
}

function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
            username,
            password
        });
        setToken(token);
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <form onSubmit={handleSubmit}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                    width: "100vh",
                    background: "white",
                    borderRadius: "10px",
                    borderStyle: "double",
                    borderColor: "black",
                    borderWidth: "5px"
                }}
            >
                <div class="container" 
                    style={{
                        color: "#039772b0", 
                        justifyContent: "center", 
                        width: "100vh", 
                        fontSize: "3.5vw"
                    }}
                >
                    <div style={{display: "block", marginBottom: "5vh"}}>
                        <label for="uname" style={{marginLeft: "1vw"}}><b>Username: </b></label>
                        <input type="text" placeholder="Enter Username" name="uname" 
                            style={{float: "right", 
                                width: "25vw", 
                                height: "5vh", 
                                marginRight: "1vw",
                                top: "50%",
                                msTransform: "translateY(50%)",
                                transform: "translateY(50%)",
                            }} 
                        onChange={e => setUserName(e.target.value)} required></input>
                    </div>

                    <div style={{display: "block"}}>
                        <label for="psw" style={{marginLeft: "1vw"}}><b>Password: </b></label>
                        <input type="password" placeholder="Enter Password" name="psw" 
                            style={{float: "right", 
                                width: "25vw", 
                                height: "5vh", 
                                marginRight: "1vw",
                                top: "50%",
                                msTransform: "translateY(50%)",
                                transform: "translateY(50%)",
                            }} 
                        onChange={e => setPassword(e.target.value)} required></input>
                    </div>
                    
                    <button 
                        style={{
                            backgroundColor: "#039772b0", 
                            color: "white", 
                            fontSize: "3vh",
                            width: "95%", 
                            height: "5vh",
                            left: "50%",
                            top: "50%",
                            msTransform: "translateX(3%)", 
                            transform: "translateX(3%)",
                            borderRadius: "10px"
                        }}
                    >Submit</button>

                    <div class="container" style={{alignSelf: "flex"}}>
                        <span style={{fontSize: "2vh", display: "table", margin: "0 auto", marginTop: "2%"}}><a href='/ForgotPassword'>Forgot password?</a></span>
                    </div>
                </div>
            </form>
        </div>
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};

export default Login;
