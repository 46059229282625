import React from 'react';

const Home = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <h1>Welcum to Eliass Webpage</h1>
        </div>
    )
}

export default Home;