import React from 'react';
import './App.css';
import Navbar from './header/navbar';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Home from './pages';
import About from './pages/about';
import Login from './pages/Login';
import Dashboard from './pages/dashboard';
import Preferences from './pages/preferences';
import NotFound from './pages/notFound';
import useToken from './useToken';

function App() {

  const { token, setToken } = useToken();

  if(!token) {
    return <Login setToken={setToken} />
  }

  return (
    <div class="container">
      <header className='App-header'>
        <Router>
          <Navbar  />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path='/Preferences' element={<Preferences />} />
          </Routes>
        </Router>
      </header>
    </div>
  );
}

export default App;